import parsePhoneNumber from "libphonenumber-js";
import {
    Currency,
    ICompany,
    IField,
    IOrder,
    IOrderMaterial,
    IOrderStage,
    IOrderType,
    IOrderWork,
    IProductType,
    Locale,
    OrderStageType,
    OrderTypeFieldGroupType,
} from "@lib";
import {
    GroupedCustomField,
    OrderPrintContext,
} from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import { IAltTableCellParams } from "@/core/components/alt-table";
import { Printer } from "@core/usecases/template/printer";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import router from "@/router/router";
import { Formatter } from "@/utils/formatter";

export function getDefaultTableColumns(context: any, showOrderStageModalFunc: Function): any[] {
    const stages = context.stages as IOrderStage[];

    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            minWidth: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (order: IOrder) => context.can.update(order),
                            click: (order: IOrder) => context.openFormUpdate(order),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (order: IOrder) => context.can.delete(order),
                            click: (order: IOrder) => context.confirmDelete(order),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "Флажок",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                const order = params.data;

                if (!order.officeRef) {
                    return "";
                }

                const orderType = context.orderTypes?.find((t: IOrderType) => t.id === order.type);

                const printContext: OrderPrintContext = {
                    company: context.company as ICompany,
                    office: order.officeRef,
                    order: order,
                    orderType: orderType,
                    productTypes: context.productTypes as IProductType[],
                };

                return Printer.replaceOrderDocumentsGeneralMacros("%(Заявка.Номер)", printContext);
            },
        },
        {
            colId: "office",
            field: "office",
            headerName: "Филиал",
            sortable: true,
            filter: true,
            width: 160,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: IAltTableCellParams<IOrder>): any => {
                return {
                    name: params.data.officeRef?.info.name ?? "",
                };
            },
            cellExport: (params: IAltTableCellParams<IOrder>): string => {
                return params.data.officeRef?.info.name ?? "";
            },
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 180,
            hide: true,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: true,
        },
        {
            colId: "product.type",
            field: "products",
            headerName: "Тип устройства",
            sortable: false,
            filter: true,
            width: 150,
            hide: true,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                const products = params.data.products;
                const typeId = products && products.length > 0 ? products[0].info.type : "";
                const type = context.productTypes.find((t: IProductType) => t.id === typeId);
                return type?.name ?? "";
            },
        },
        {
            colId: "product.name",
            field: "products",
            headerName: "Устройство",
            sortable: false,
            filter: true,
            width: 180,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                const products = params.value;
                return products && products.length > 0 ? products[0].info.name : "";
            },
        },
        {
            colId: "product.description",
            field: "products",
            headerName: "Неисправность",
            sortable: false,
            filter: true,
            width: 300,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                const products = params.value;
                return products && products.length > 0 ? products[0].info.description : "";
            },
        },
        {
            colId: "stage",
            field: "stage",
            headerName: "Этап",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            // cellRenderer: function(params: any) {
            //     console.log(params);
            //     const stage = stages.find(stage => stage.id === params.value);
            //     return stage ? stage.name : "-";
            // },
            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: IAltTableCellParams<IOrder>): any => {
                const stage = stages.find(stage => stage.id === params.value);
                return {
                    color: stage ? stage.color : "dark",
                    name: stage ? stage.name : "-",
                    click: () => showOrderStageModalFunc(params.data),
                };
            },
            cellExport: (params: IAltTableCellParams<IOrder>): string => {
                const stage = stages.find(stage => stage.id === params.value);
                return stage?.name ?? "-";
            },
        },
        {
            colId: "price",
            headerName: "Цена",
            sortable: false,
            filter: true,
            width: 140,
            hide: true,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                const currency = params.data.officeRef?.info.currency ?? Currency.RUB;
                const locale = params.data.officeRef?.info.locale ?? Locale.RU;
                const materials = params.data.materials as IOrderMaterial[];
                const works = params.data.works as IOrderWork[];
                let sum = 0;

                if (materials) {
                    for (const material of materials) {
                        sum += material.price * material.quantity;
                    }
                }

                if (works) {
                    for (const work of works) {
                        sum += work.price * work.quantity;
                    }
                }

                return Localizer.currency(sum, currency, locale);
            },
        },
        {
            colId: "client",
            field: "client",
            headerName: "Клиент",
            sortable: true,
            filter: true,
            width: 180,
            hide: false,
            cellClass: "alt-grid-cell-multiline",
            autoHeight: true,

            cellRendererFramework: "CellRendererDynamic",
            cellRendererParams: (params: IAltTableCellParams<IOrder>): any => {
                const client = params.data.clientRef;
                if (!client) {
                    return "";
                }

                const elements: any[] = [];

                elements.push({
                    type: "link",
                    class: "text-dark hover:underline",
                    text: client.info.name,
                    meta: {
                        click: () => router.push({ name: "clients", query: { id: client.id } }).catch(() => {}),
                    },
                });

                if (client.info.contacts?.phone) {
                    elements.push({ type: "br" });

                    const clientPhone = client.info.contacts.phone;
                    // const phoneNumber = parsePhoneNumber(clientPhone);
                    const phoneNumber = Formatter.phone(clientPhone);

                    elements.push({
                        type: "link",
                        class: "text-dark hover:underline",
                        text: phoneNumber ?? clientPhone, //phoneNumber?.formatInternational() ?? clientPhone,
                        meta: {
                            href: `tel:${client.info.contacts.phone}`,
                        },
                    });
                }

                if (client.info.contacts?.email) {
                    elements.push({ type: "br" });

                    elements.push({
                        type: "link",
                        class: "text-dark hover:underline",
                        text: client.info.contacts.email,
                        meta: {
                            href: `mailto:${client.info.contacts.email}`,
                        },
                    });
                }

                return { elements };
            },
            cellExport: (params: IAltTableCellParams<IOrder>): string => {
                const client = params.data.clientRef;

                if (!client) {
                    return "";
                }

                let info = client.info.name;

                if (client.info.contacts?.phone) {
                    const clientPhone = client.info.contacts.phone;
                    const phoneNumber = parsePhoneNumber(clientPhone);

                    info += " | " + phoneNumber?.formatInternational() ?? clientPhone;
                }

                if (client.info.contacts?.email) {
                    info += " | " + client.info.contacts.email;
                }

                return info;
            },
        },
        {
            colId: "manager",
            field: "manager",
            headerName: "Ответственный",
            sortable: true,
            filter: true,
            width: 220,
            hide: false,

            cellRendererFramework: "CellRendererAvatar",
            cellRendererParams: (params: IAltTableCellParams<IOrder>): any => {
                const manager = params.data.managerRef;
                return {
                    showAvatar: true,
                    avatar: "", //manager ? manager.info.avatar : "",
                    name: manager ? manager.info.name : "",
                };
            },
            cellExport: (params: IAltTableCellParams<IOrder>): string => {
                const manager = params.data.managerRef;
                return manager?.info?.name ?? "-";
            },
        },
        {
            colId: "created",
            field: "createdAt",
            headerName: "Дата создания",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                return filters.datetime.datetime(params.value, "L LT");
            },
        },
        {
            colId: "doneAt",
            field: "doneAt",
            headerName: "Дата завершения",
            sortable: true,
            filter: true,
            width: 180,
            hide: true,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                if (!params.data.done || !params.data.doneAt) {
                    return "";
                }

                return filters.datetime.datetime(params.data.doneAt, "L LT");
            },
        },
        {
            colId: "deadline",
            field: "info.deadline",
            headerName: "Крайний срок",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                return params.value ? filters.datetime.fromNow(params.value) : "";
            },
        },

        ...getCustomColumns(context),
    ];
}

export function getCustomColumns(context: any): any[] {
    const customColumns: any[] = [];
    const groupedCustomFields = getCustomFields(context);
    const customFieldIds: string[] = [];

    for (const gf of groupedCustomFields) {
        // убираем повторяющиеся поля
        if (customFieldIds.includes(gf.field.id)) {
            continue;
        }

        const { field, group } = gf;
        customFieldIds.push(field.id);

        customColumns.push({
            colId: field.id,
            headerName: field.title,
            sortable: false,
            filter: false,
            width: 160,
            hide: true,

            cellRenderer: (params: IAltTableCellParams<IOrder>): string => {
                // TODO: повтор, как в order-macro-opener
                const order: IOrder = params.data;
                const key = field.customId ?? field.details1 ?? field.id;
                let value: any;

                if (group.type === OrderTypeFieldGroupType.Client) {
                    value = order.clientRef?.custom ? order.clientRef.custom[key] : undefined;
                } else if (group.type === OrderTypeFieldGroupType.Product && order.products.length > 0) {
                    value = order.products[0].custom ? order.products[0].custom[key] : undefined;
                } else {
                    value = order.custom ? order.custom[key] : undefined;
                }

                const locale = order?.officeRef?.info?.locale;
                return FieldControlFactory.formatValue(value, field, { locale });
            },
        });
    }

    return customColumns;
}

export function getCustomFields(context: any): GroupedCustomField[] {
    const fields: GroupedCustomField[] = [];

    if (context.orderTypes.length === 0) {
        return fields;
    }

    for (const orderType of context.orderTypes) {
        for (const group of orderType.groups) {
            for (const field of group.fields as IField[]) {
                if (field.custom && !field.hidden) {
                    fields.push({ field, group });
                }
            }
        }
    }
    return fields;
}

export function getDefaultTableActions(context: any): any[] {
    return [
        {
            name: "Удалить всё",
            icon: "Trash2Icon",
            click: (orders: IOrder[]) => context.confirmDeleteMany(orders),
        },
    ];
}

export function getOrderStageColor(stage: OrderStageType): string {
    switch (stage) {
        case OrderStageType.New:
            return "primary";
        case OrderStageType.InWork:
        case OrderStageType.Deferred:
            return "warning";
        case OrderStageType.Ready:
            return "success";
        case OrderStageType.Closed:
        case OrderStageType.Canceled:
            return "secondary";
        default:
            return "danger";
    }
}

export function getOrderStageText(stage: OrderStageType): string {
    switch (stage) {
        case OrderStageType.New:
            return "Создано";
        case OrderStageType.InWork:
        case OrderStageType.Deferred:
            return "В работе";
        case OrderStageType.Ready:
            return "Готово";
        case OrderStageType.Closed:
            return "Закрыто";
        case OrderStageType.Canceled:
            return "Отменено";
        default:
            return "Неизвестно";
    }
}
